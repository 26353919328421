class MapDataService {
  // constuctor() {
  // }

  // TODO share common fetch code
  static async getTimingsForStation(station) {
    // TODO cache
    let response;
    try {
      response = await fetch(`https://www.robdev.org.uk/journey_time_data/${encodeURI(station)}.json`);
    } catch (e) {
      throw new Error(`timings request failed: ${e.message}`);
    }
    if (!response.ok) {
      throw new Error(`timings response failed: ${response.statusText}`);
    }
    try {
      return await response.json();
    } catch (e) {
      throw new Error(`timings JSON failed to parse: ${e.message}`);
    }
  }

  static async getStations() {
    // TODO cache
    let response;
    try {
      response = await fetch('https://www.robdev.org.uk/journey_time_data/stationLocations.json');
    } catch (e) {
      throw new Error(`stations request failed: ${e.message}`);
    }
    if (!response.ok) {
      throw new Error(`stations response failed: ${response.statusText}`);
    }
    try {
      return await response.json();
    } catch (e) {
      throw new Error(`stations JSON failed to parse: ${e.message}`);
    }
  }

  static async getStationsList() {
    let response;
    try {
      response = await fetch('https://www.robdev.org.uk/journey_time_data/stationsList.json');
    } catch (e) {
      throw new Error(`stationsList request failed: ${e.message}`);
    }
    if (!response.ok) {
      throw new Error(`stationsList response failed: ${response.statusText}`);
    }
    try {
      return await response.json();
    } catch (e) {
      throw new Error(`stationsList JSON failed to parse: ${e.message}`);
    }
  }
}

export default MapDataService;
