<template>
  <div>
    <v-select
      :items="stations"
      :value="value"
      @input="$emit('input', $event)"
      label="Start station"
      :error-messages="errorMessage"
      menu-props="auto"
      dark
    ></v-select>
  </div>

</template>

<script>
import MapDataService from '../services/MapDataService';

export default {
  name: 'SelectorPlace',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    isValid() {
      if (this.stations.length === 0) return true;
      return this.stations.indexOf(this.value) !== -1;
    },
    errorMessage() {
      return this.isValid ? '' : 'Select a station from the list';
    },
  },
  data() {
    return {
      stations: [],
      stationsPromise: MapDataService.getStationsList(),
    };
  },
  watch: {
    isValid() {
      this.$emit('isValid', this.isValid);
    },
  },
  created() {
    this.stationsPromise.then((stations) => {
      this.stations = stations;
      this.$emit('isValid', this.isValid);
    }).catch((err) => {
      throw new Error(`Failed to load stations: ${err.message}`);
    });
  },
};
</script>

<style scoped>
</style>
