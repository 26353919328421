/* globals gtag */
import Vue from 'vue';

import App from './App.vue';
// import App from './VuetifyApp.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  const errorDescription = `vue error: ${err} - info: ${info}`;
  gtag('event', 'exception', {
    description: errorDescription,
    fatal: false,
  });
};

new Vue({
  router,
  vuetify,
  render(h) { return h(App); },
}).$mount('#app');
