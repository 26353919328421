import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#002d80',
        secondary: '#4a55b0',
        error: 'ff9090',
      },
    },
    options: {
      variations: false,
    },
  },
});
