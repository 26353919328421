<template>
  <div>
    <v-select
      id="timeselect"
      :items="items"
      :value="value"
      @input="$emit('input', $event)"
      label="Time (mins)"
      hint="Stations show as green when reachable by this time"
      persistent-hint
      dark
    ></v-select>
  </div>

</template>

<script>
export default {
  name: 'SelectorTime',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [10, 20, 30, 40, 50, 60, 70, 80, 90],
    };
  },
};
</script>

<style scoped>
</style>
