<template>
  <div>
    <v-select
      id="maptypeselect"
      :items="items"
      :value="value"
      @input="$emit('input', $event)"
      label="Map type"
      persistent-hint
      :hint=hintText
      dark
      single-line
    ></v-select>
  </div>

</template>

<script>
export default {
  name: 'SelectorMapType',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          text: 'Contours every 10 mins from start station',
          value: 'contours',
        },
        {
          text: 'Stations reachable from start station by ? mins',
          value: 'reachable',
        },
        {
          text: 'Station proximity heatmap',
          value: 'heatmap',
        },
      ],
    };
  },
  computed: {
    hintText() {
      return `Choose from ${this.items.length} map types`;
    },
  },
};
</script>

<style scoped>
</style>
