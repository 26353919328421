<template>
  <div id="map" :data-place-name='placeName' class="primary">
    <div id="mapfallbacktext" v-show="this.mapsScriptNotLoaded">
      <h1>Journey time map from {{placeName}}</h1>
      <p><i>Journey Time Maps for London</i> shows the travel time from every London underground
      station to other tube stations in London.  <a href='/about'>Read about tube journey time maps
      for London</a>.
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* globals google */
import Map from '../domain/Map';

class MapsLoader {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  callback() {
    // console.debug('MapWithKml script callback called');
    this._resolve();
  }

  onerror() {
    // console.error('MapWithKml failed to load google maps script');
    this._reject(new Error('Failed to load google maps script'));
  }
}

const MAP_TYPES = ['contours', 'reachable', 'heatmap'];

function makeScriptEl() {
  const apiKey = document.body.dataset.mapsApiKey;
  if (!apiKey) {
    throw new Error('Missing data-maps-api-key on body element');
  }
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=visualization&callback=mapsLoader.callback`;
  script.defer = true;
  script.async = true;
  script.onerror = () => window.mapsLoader.onerror();
  script.id = 'mapsscript';
  document.head.appendChild(script);
}

export default {
  name: 'MapWithKml',
  props: {
    placeName: {
      required: true,
      validator(value) {
        return (typeof value === 'string' && value !== '') || value == null;
      },
    },
    mapType: {
      required: true,
      type: String,
      validator(value) {
        return MAP_TYPES.indexOf(value) !== -1;
      },
    },
    time: {
      required: true,
      type: Number,
    },
  },
  computed: {
    // foo() {
    // },
  },
  data() {
    return {
      mapsScriptNotLoaded: false,
    };
  },
  beforeCreate() {
    if (!document.querySelector('#mapsscript')) {
      window.mapsLoader = new MapsLoader();
      makeScriptEl();
    }
  },
  created() {
    window.mapsLoader.promise.then(() => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        backgroundColor: '#002d80',
      });
      this.mapWrap = new Map(this.map);
      this.updateMapLayer();
    }).catch((err) => {
      this.mapsScriptNotLoaded = true;
      throw new Error(`Failed to initialise google maps: ${err.message}`);
    });
  },
  methods: {
    updateMapLayer() {
      if (this.mapType === 'contours' && this.placeName) {
        this.mapWrap.showContourMap(this.placeName);
      } else if (this.mapType === 'reachable' && this.placeName) {
        this.mapWrap.showReachableMap(this.placeName, this.time);
      } else if (this.mapType === 'heatmap') {
        this.mapWrap.showHeatmap();
      }
    },
  },
  watch: {
    placeName() {
      this.updateMapLayer();
    },
    mapType() {
      this.updateMapLayer();
    },
    time() {
      this.updateMapLayer();
    },
  },
};
</script>

<style scoped>
#map {
    height: 100%;
}

#mapfallbacktext {
  color: white;
  padding: 50px;
}
#mapfallbacktext a {
  color: white;
}
</style>
