<template>
  <v-app id="app">

    <v-navigation-drawer
      v-model="drawer"
      app
      mini-variant
    >
      <v-list>
        <v-list-item link :to='homeButtonTarget'>
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to='{ name: "About" }'>
          <v-list-item-action>
            <v-icon>mdi-information</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      class="primary" style="padding-bottom: 70px"
      flat
      :extension-height="appBarExtensionHeight"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-row align="center">
        <v-col class="d-none d-md-flex" md="4" >
          <v-toolbar-title>Journey Time Maps for London</v-toolbar-title>
        </v-col>

        <v-col cols="12" sm="10" md="8" lg="6" xl="4" offset-sm="1" offset-md="0" offset-lg="1">
          <SelectorMapType
            v-model="mapType"
          />
        </v-col>
      </v-row>
      <template v-slot:extension>
        <v-row class="pl-8">
          <v-col v-if="showPlaceSelector"
            cols="6" sm="5" md="4" lg="3" xl="2" offset-sm="1" offset-md="4" offset-lg="5">
            <SelectorPlace
                v-model="placeName"
                @isValid="isPlaceValid = $event"
              />
          </v-col>
          <v-col cols="6" sm="5" md="4" lg="3" xl="2" v-if="showTimeSelector">
            <SelectorTime
              v-model.number="time"
            />
          </v-col>
        </v-row>
      </template>
    </v-app-bar>

    <v-main>
      <router-view
        :mapType="mapType"
        :placeName="validatedPlaceName"
        :time="time"
      ></router-view>
    </v-main>

  </v-app>
</template>

<script>
/* global gtag */
import SelectorMapType from '@/components/SelectorMapType.vue';
import SelectorPlace from '@/components/SelectorPlace.vue';
import SelectorTime from '@/components/SelectorTime.vue';

export default {
  components: {
    SelectorMapType,
    SelectorPlace,
    SelectorTime,
  },
  data() {
    const mapType = this.$route.params.placeName ? 'contours' : 'heatmap';
    return {
      drawer: null,
      isPlaceValid: null,
      mapType,
      time: 10,
    };
  },
  computed: {
    isValid: {
      get() {
        if (this.$route.params.placeName) return this.isPlaceValid;
        return false;
      },
    },
    placeName: {
      get() {
        return this.$route.params.placeName || this.previousPlaceName || null;
      },
      set(val) {
        this.$router.push({ name: 'AreaWithPlace', params: { placeName: val } });
      },
    },
    homeButtonTarget() {
      if (this.mapType === 'heatmap') return { name: 'Area' };
      return { name: 'AreaWithPlace', params: { placeName: this.placeName } };
    },
    appBarExtensionHeight() {
      return this.showPlaceSelector ? 100 : 0;
    },
    showPlaceSelector() {
      return this.mapType !== 'heatmap';
    },
    showTimeSelector() {
      return this.mapType === 'reachable';
    },
    title() {
      if (this.isValid && this.$route.params.placeName) {
        return `Transport journey time map from ${this.$route.params.placeName}`;
      }
      if (this.mapType === 'heatmap') {
        return 'Station proximity | Transport journey time maps for London';
      }
      return 'Transport journey time maps for London';
    },
    validatedPlaceName() {
      return this.isValid ? this.placeName : null;
    },
  },
  beforeCreate() {
    this.analyticsId = document.body.dataset.analyticsId || null;
  },
  created() {
    if (this.placeName) {
      this.previousPlaceName = this.placeName;
    }
  },
  watch: {
    mapType() {
      // update the route based on the selected mapType
      if (this.$route.params.placeName && this.mapType === 'heatmap') {
        this.$router.push({ name: 'Area' });
      } else if (!this.$route.params.placeName && this.mapType !== 'heatmap' && this.previousPlaceName) {
        this.$router.push({ name: 'AreaWithPlace', params: { placeName: this.previousPlaceName } });
      }
    },
    placeName() {
      if (this.placeName) {
        this.previousPlaceName = this.placeName;
      }
    },
    $route(to) {
      // update the mapType if the route is changed with browser back
      if (to.name === 'AreaWithPlace' && this.mapType === 'heatmap') {
        this.mapType = 'contours';
      } else if (to.name === 'Area' && this.mapType !== 'heatmap') {
        this.mapType = 'heatmap';
      }
    },
    title() {
      document.title = this.title;
      gtag('config', this.analyticsId, {
        page_title: this.title,
        path_path: this.$router.currentRoute.path,
      });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
