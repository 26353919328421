import Vue from 'vue';
import VueRouter from 'vue-router';
import MapWithKml from '../components/MapWithKml.vue';
import NotFound from '../views/NotFound.vue';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      const path = decodeURI(window.location.search.substring(1));
      if (window.location.host !== 'webcache.googleusercontent.com'
          && path && path !== '/') {
        next({ path, replace: true });
      } else {
        next({ path: '/maps/london', replace: true });
      }
    },
  },
  {
    path: '/maps',
    redirect: '/maps/london',
  },
  {
    path: '/maps/london',
    name: 'Area',
    component: MapWithKml,
  },
  {
    path: '/maps/london/:placeName',
    beforeEnter: (to, from, next) => {
      if (to.path.match(/\.html$/)) {
        const path = to.path.replace(/\.html$/, '');
        next({ path, replace: true });
      }
      next();
    },
    name: 'AreaWithPlace',
    component: MapWithKml,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
