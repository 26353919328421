/* global google */
import MapDataService from '../services/MapDataService';

function setReachableColours(map, timeMins) {
  const threshold = timeMins * 60;
  const insideSize = 20;
  const outsideSize = 10;
  map.data.setStyle((feature) => {
    const time = feature.getProperty('time');
    let colour = 'red';
    let scale = outsideSize;
    if (time <= threshold) {
      colour = 'green';
      scale = insideSize + ((threshold - time) * (insideSize / threshold));
    } else if (Number.isNaN(time)) {
      colour = 'black';
    }
    return {
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale,
        fillColor: colour,
        fillOpacity: 0.35,
        strokeWeight: 1,
        strokeColor: colour,
      },
    };
  });
}

function getContourKmlUrl(placeName) {
  let encodedName = encodeURI(placeName);
  encodedName = encodedName.replace('/', '_');
  return `https://www.robdev.org.uk/tube_journey_time_kml/${encodedName}.kml`;
}

class Map {
  constructor(map) {
    this.map = map;
    this.kmlLayer = new google.maps.KmlLayer(null, { map: null });

    this.mapViewportIsSet = false;
  }

  showContourMap(place) {
    if (this.lastLayer === 'contour' && this.previousPlace === place) return;
    this.previousPlace = place;
    this.lastLayer = 'contour';

    // this.kmlLayer.setOptions({ preserveViewport: true });
    this.hideReachableMap();
    this.hideHeatmap();
    const url = getContourKmlUrl(place);
    // Don't preserve first time
    this.kmlLayer.setOptions({ preserveViewport: this.mapViewportIsSet });
    this.kmlLayer.setUrl(url);
    this.kmlLayer.setMap(this.map);
    this.mapViewportIsSet = true;
  }

  showReachableMap(startStation, time) {
    if (this.lastLayer === 'reachable' && startStation === this.previousPlace) {
      setReachableColours(this.map, time);
      return;
    }
    this.previousPlace = startStation;
    this.lastLayer = 'reachable';

    const promises = [];
    promises.push(MapDataService.getStations());
    promises.push(MapDataService.getTimingsForStation(startStation));
    Promise.all(promises).then((values) => {
      const stations = values[0];
      const timings = values[1];
      this.hideContourMap();
      this.hideHeatmap();
      setReachableColours(this.map, time);
      this._initMapViewPort();
      Object.keys(stations).forEach((station) => {
        if (station in timings) {
          this.map.data.add({
            geometry: { lat: stations[station].lat, lng: stations[station].lon },
            id: station,
            properties: {
              time: timings[station],
            },
          });
        } else {
          // console.warn(`no timing for ${station}`);
        }
      });
    });
  }

  async showHeatmap() {
    if (this.lastLayer === 'heatmap') return;
    this.lastLayer = 'heatmap';

    if (this.heatmapLayer) {
      this.hideContourMap();
      this.hideReachableMap();
      this.heatmapLayer.setMap(this.map);
      return;
    }

    this._initHeatmapLayer();
  }

  async _initHeatmapLayer() {
    const stations = await MapDataService.getStations();
    this.hideContourMap();
    this.hideReachableMap();
    const data = Object.keys(stations).map((v) => ({
      location: new google.maps.LatLng(stations[v].lat, stations[v].lon),
    }));
    this._initMapViewPort();
    this.heatmapLayer = new google.maps.visualization.HeatmapLayer({
      data,
      map: this.map,
      radius: 20,
      dissipating: true,
    });
  }

  hideHeatmap() {
    if (this.heatmapLayer) {
      this.heatmapLayer.setMap(null);
    }
  }

  hideReachableMap() {
    this.map.data.forEach((feature) => {
      this.map.data.remove(feature);
    });
  }

  hideContourMap() {
    this.kmlLayer.setMap(null);
  }

  _initMapViewPort() {
    if (!this.mapViewportIsSet) {
      // First time need to postion the map if not already positioned
      this.map.setZoom(13);
      this.map.setCenter({ lat: 51.513112, lng: -0.145092 });
      this.mapViewportIsSet = true;
    }
  }
}

export default Map;
